import { createWrapper } from "./AxiosWrapper";

export async function getDynamicPackageNextPage(
  id: any,
  searchGroupId: any
): Promise<any> {
  return createWrapper()
    .get(
      `/api/search_dynamic_packages/${id}/search_groups/${searchGroupId}/next.json`
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        nextData: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function postDynamicPackagePreReservation(
  param: any
): Promise<any> {
  return createWrapper()
    .post("/api/dynamic_package_pre_reservations.json", {
      dynamic_package_pre_reservation: param,
    })
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function fetchDynamicPackagePreReservation(
  dynamicPackagePreReservationId: string
): Promise<any> {
  return createWrapper()
    .get(
      `/api/dynamic_package_pre_reservations/${dynamicPackagePreReservationId}.json`
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function putDynamicPackagePreReservation(
  dynamicPackagePreReservationId: string,
  param: any
): Promise<any> {
  return createWrapper()
    .put(
      `/api/dynamic_package_pre_reservations/${dynamicPackagePreReservationId}.json`,
      { dynamic_package_pre_reservation: param }
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function updateDynamicPackagePreReservationParameters(
  dynamicPackagePreReservationId: string,
  flightPreReservationId: string,
  param: any
): Promise<any> {
  return createWrapper()
    .put(
      `/api/dynamic_package_pre_reservations/${dynamicPackagePreReservationId}/flight_pre_reservations/${flightPreReservationId}.json`,
      param
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function postCreditCards(
  dynamicPackagePreReservationId: string,
  param: any
): Promise<any> {
  return createWrapper()
    .post(
      `/api/dynamic_package_pre_reservations/${dynamicPackagePreReservationId}/credit_cards.json`,
      { reservation: param }
    )
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
        errorCode: error.response.data.error_code,
        errorMessages: error.response.data.error_messages,
      });
    });
}
