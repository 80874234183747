import { createWrapper } from "./AxiosWrapper";

export async function postFlightPreReservation(param: any): Promise<any> {
  return createWrapper()
    .post("/api/flight_pre_reservations.json", {
      flight_pre_reservation: param,
    })
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function fetchFlightPreReservation(
  flightPreReservationId: string
): Promise<any> {
  return createWrapper()
    .get(`/api/flight_pre_reservations/${flightPreReservationId}.json`)
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}

export async function updateFlightPreReservationParameters(
  flightPreReservationId: string,
  param: any
): Promise<any> {
  return createWrapper()
    .put(`/api/flight_pre_reservations/${flightPreReservationId}.json`, param)
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}
