import { createWrapper } from "./AxiosWrapper";

export async function fetchReservation(
  reservationId: number,
  token: string
): Promise<any> {
  const urlParam: string = token ? `?token=${token}` : "";
  // return createWrapper({ isCsrf: true })
  return createWrapper()
    .get(`/api/reservations/${reservationId}.json${urlParam}`)
    .then(async (response: any): Promise<any> => {
      return Promise.resolve({
        data: response.data,
      });
    })
    .catch(async (error: any): Promise<any> => {
      return Promise.reject({
        errors: error.response.data.errors,
      });
    });
}
