export const GOOGLE_API_KEY = "AIzaSyBArR9Cwl5P3353rIRzNZaKdi0fl1y9TMI";
export const FACEBOOK_MESSENGER_SDK_URL =
  "//connect.facebook.net/en_US/messenger.Extensions.js";
export const FACEBOOK_APP_ID: string =
  process.env.APP_ENV === "production" ? "155154885266424" : "428526084571102";
export const LINE_LIFF_SDK_URL =
  "https://static.line-scdn.net/liff/edge/2/sdk.js";
export const GTM_ID =
  process.env.HOSTING_DOMAIN === "travelvision" ? "GTM-M5SWWT3" : "GTM-KWZ3KN4";
// export const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
// new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
// j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
// 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
// })(window,document,'script','dataLayer','${GTM_ID}');`;
// export const GTM_NOSCRIPT = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
// height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

export const GTM_SCRIPT =
  process.env.APP_ENV === "production"
    ? `window.dataLayer = window.dataLayer || [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`
    : `window.dataLayer = window.dataLayer || [];(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=JoJToszBHzWYleFaove3VQ&gtm_preview=env-6&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_ID}');`;
export const GTM_NOSCRIPT =
  process.env.APP_ENV === "production"
    ? `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    : `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}&gtm_auth=JoJToszBHzWYleFaove3VQ&gtm_preview=env-6&gtm_cookies_win=x" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

export const PAYMENT_TOKEN_URL: string =
  process.env.APP_ENV === "production"
    ? "https://static.mul-pay.jp/ext/js/token.js"
    : "https://stg.static.mul-pay.jp/ext/js/token.js";
export const PATH_SCOPE: string =
  process.env.HOSTING_DOMAIN === "travelvision" ? "/tarip" : "";
export const TABI_DOMAIN =
  process.env.APP_ENV === "production"
    ? "https://tabi-two.com"
    : "http://localhost:8000";
export const TOP_LIFF_ID =
  process.env.APP_ENV === "production" ? "1495897092-bRapGVoK" : null;

export const HOST: any = process.env.BASE_URL;
