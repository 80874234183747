import { handleActions } from "redux-actions";
import { INIT_ACCOUNT_PARAMS } from "main/javascripts/constants/actionType/AccountParamActionType";

export interface IAccountParamsState {
  utmSource: string | undefined | null;
  lineRegisterUrl: string | undefined | null;
  lineType: string | undefined | null;
  researchFlightLiffId: string | undefined | null;
  researchHotelLiffId: string | undefined | null;
  origins: any[];
  destinations: any[];
}

const INITIAL_STATE: IAccountParamsState = {
  utmSource: null,
  lineRegisterUrl: null,
  lineType: null,
  researchFlightLiffId: null,
  researchHotelLiffId: null,
  origins: [],
  destinations: [],
};

export const accountParamsReducer: any = handleActions(
  {
    [INIT_ACCOUNT_PARAMS]: (
      state: IAccountParamsState,
      action: any
    ): IAccountParamsState => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  INITIAL_STATE
);
